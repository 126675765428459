// useModalState.js
import { ref, provide, inject } from 'vue';

const modalStateKey = Symbol('modalState');

export function provideModalState() {
    const isModalActive = ref(false);

    const setModalState = (state) => {
        isModalActive.value = state;
    };

    const closeModal = () => {
        isModalActive.value = false;
    };

    provide(modalStateKey, {
        isModalActive,
        setModalState,
        closeModal
    });

    return {
        isModalActive,
        setModalState,
        closeModal
    };
}

export function useModalState() {
    const modalState = inject(modalStateKey);

    if (!modalState) {
        throw new Error('Modal state not provided');
    }

    return modalState;
}
