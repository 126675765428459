<template>
  <div class="app-container">
    <Header />
    <div class="intro-text" v-if="showIntroText">
      <p v-html="mainText"></p>
    </div>
    <main class="main-content">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import {useRoute} from "vue-router";
import {computed} from "vue";
import { provideModalState } from '@/hooks/useModalState';


export default {
  components: {
    Header
  },
  setup() {
    const route = useRoute()

    provideModalState();

    const showIntroText = computed(() => {
      // Show intro text only when we're at the root path or viewing projects list
      return route.path === '/' || route.path === '/projects'
    })

    const mainText = computed(() => {
      return 'IS UKRAINIAN PHOTO-ARTIST REPRESENTED IN 28 MUSEUMS, INCLUDING POMPIDU CENTRE OF CONTENPORARY ARTS, CINCINATTI MUSEUM AND JOAN MIRO CENTRE. IN HIS 32 YEARS OF PRACTIVE EVGENIY GONE THE PATH FROM OBSTRUCTIVE DOCUMENTALISM TO MONUMENTAL POST-RELIGOUS  FRESCAS, DEPICTING BOTH EVERYDAY LIFE AND OTHER SIDE OF OUR REALITY'
    })

    return {
      showIntroText,
      mainText
    }
  }
};
</script>

<style>
@import './assets/styles/styles.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.main-content {
  position: relative;
}
</style>