<template>
  <div class="project-info" @click="handleHeaderClick">
    <div class="project-header">
      <h2>{{ name }}</h2>
      <span class="project-date">{{ date }}</span>
    </div>
    <div class="project-description"
         @click.stop="toggleDescription"
    >
      <p>
        <span v-if="description" class="description-text">
          {{
            description.length > 120 && expanded
                ? description
                : description.slice(0, 120) +
                (description.length > 30 ? '...' : '')
          }}
        </span>
        <span
            v-if="description && description.length > 120"
            class="show-more"

        >
          {{ expanded ? '-' : '+' }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectInfo',
  props: {
    name: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expanded: false, // Moved from props to data
    };
  },
  methods: {
    handleHeaderClick() {
      this.$emit('click-header');
    },
    toggleDescription(event) {
      event.stopPropagation(); // Prevents the click from bubbling up
      this.expanded = !this.expanded;
    },
  },
};
</script>
