// hooks/useFullscreen.js
import { ref } from 'vue';
import { getFullUrl } from '@/api'; // Import if needed
import { nextTick } from 'vue';

export function useFullscreen() {
    const fullscreenProject = ref(null);
    const clickedImageIndex = ref(null);
    const imagesAreVisible = ref(false);
    const highResImagesLoaded = ref({});

    const loadHighResImages = async (project) => {
        if (!highResImagesLoaded.value[project.id]) {
            highResImagesLoaded.value[project.id] = {};
        }

        const promises = project.attributes.images.data.map((image) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.src = getFullUrl(
                    image.attributes.formats.large?.url ||
                    image.attributes.formats.medium?.url ||
                    image.attributes.formats.small?.url ||
                    image.attributes.url
                );
                img.onload = () => {
                    highResImagesLoaded.value[project.id][image.id] = true;
                    resolve();
                };
                img.onerror = () => {
                    console.error(`Error loading image: ${img.src}`);
                    resolve();
                };
            });
        });

        await Promise.all(promises);
    };

    const scrollToImage = async (project, imageIndex) => {
        await nextTick();

        const imageContainers = document.querySelectorAll(
            `.project-row-enlarged[data-project-id="${project.id}"] .image-container-enlarged`
        );

        if (imageContainers[imageIndex]) {
            imageContainers[imageIndex].scrollIntoView({
                behavior: 'auto',
                inline: 'center',
            });
        }
    };

    const openFullscreen = async (project, imageIndex) => {
        fullscreenProject.value = project;
        clickedImageIndex.value = imageIndex;
        imagesAreVisible.value = false;

        try {
            await loadHighResImages(project);
            imagesAreVisible.value = true;

            await nextTick();
            scrollToImage(project, imageIndex);
        } catch (error) {
            console.error('Error loading high-resolution images:', error);
        }
    };

    const closeFullscreen = () => {
        fullscreenProject.value = null;
        clickedImageIndex.value = null;
        imagesAreVisible.value = false;
    };

    return {
        fullscreenProject,
        clickedImageIndex,
        imagesAreVisible,
        openFullscreen,
        closeFullscreen,
    };
}