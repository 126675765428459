<template>
  <div
      class="image-grid"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
      :class="computedClasses.imageGrid"
  >
    <div
        v-if="fullscreenProject && fullscreenProject.id === project.id"
        class="image-navigation-overlay"
        @click.stop="handleModalClick"
        aria-label="Image Navigation Overlay"
        role="button"
        tabindex="0"
        @keydown.enter.prevent="handleModalClick"
        @keydown.space.prevent="handleModalClick"
    ></div>

    <div
        v-for="(image, index) in project.attributes.images.data"
        :key="image.id"
        class="image-container"
        :class="computedClasses.imageContainer"
    >
      <img
          :src="getImageUrl(image, project)"
          :alt="image.attributes.name"
          class="thumbnail"
          loading="lazy"
          :class="computedClasses.thumbnail"
          @click="handleImageClick(index, $event)"
          @load="onImageLoad(project.id, image.id)"
          draggable="false"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { getImageUrl } from '@/utils/imageLoader';

export default {
  name: 'ImageGrid',
  props: {
    project: {
      type: Object,
      required: true
    },
    fullscreenProject: {
      type: Object,
      default: null
    },
    imagesAreVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['image-click', 'modal-click', 'touch-start', 'touch-move', 'touch-end', 'image-load'],
  setup(props, { emit }) {
    const computedClasses = computed(() => ({
      imageGrid: {
        'image-grid-enlarged': props.fullscreenProject?.id === props.project?.id,
        'image-grid-hidden': props.fullscreenProject && props.fullscreenProject.id !== props.project?.id,
      },
      imageContainer: {
        'image-container-enlarged': props.fullscreenProject?.id === props.project?.id,
        'image-container-hidden': props.fullscreenProject && props.fullscreenProject.id !== props.project?.id,
      },
      thumbnail: {
        'thumbnail-enlarged': props.fullscreenProject?.id === props.project?.id,
        'thumbnail-hidden': props.fullscreenProject && props.fullscreenProject.id !== props.project?.id,
        'images-visible': props.fullscreenProject?.id === props.project?.id && props.imagesAreVisible,
      }
    }));

    const handleImageClick = (index, event) => {
      emit('image-click', props.project, index, event);
    };

    const handleModalClick = (event) => {
      emit('modal-click', event);
    };

    const handleTouchStart = (event) => {
      emit('touch-start', event);
    };

    const handleTouchMove = (event) => {
      emit('touch-move', event);
    };

    const handleTouchEnd = (event) => {
      emit('touch-end', event);
    };

    const onImageLoad = (projectId, imageId) => {
      emit('image-load', projectId, imageId);
    };

    return {
      computedClasses,
      handleImageClick,
      handleModalClick,
      handleTouchStart,
      handleTouchMove,
      handleTouchEnd,
      onImageLoad,
      getImageUrl
    };
  }
};
</script>
