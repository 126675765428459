<template>
  <div class="index">
    <div class="projects">
      <div
          v-for="project in sortedProjects"
          :key="project.id"
          :class="projectRowClasses(project)"
          :data-project-id="project.id"
      >
        <ProjectInfo
            :name="project.attributes.name"
            :date="project.attributes.date"
            :description="project.attributes.description"
            @click-header="handleHeaderClick(project)"
        />
        <transition name="fade" mode="out-in">
          <ImageGrid
              :project="project"
              :fullscreen-project="fullscreenProject"
              :images-are-visible="imagesAreVisible"
              @image-click="handleImageClick"
              @modal-click="handleModalClick"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, computed, onMounted, nextTick, onUnmounted, watch} from 'vue';

import ProjectInfo from './projects/ProjectInfo.vue';
import ImageGrid from './projects/ImageGrid.vue';

import {fetchProjects } from '@/api';
import {getImageUrl, loadImage, findAdjacentProject, loadHighResImages } from '@/utils/imageLoader';
import { useFullscreen } from '@/hooks/useFullscreen';
import {useModalState} from "@/hooks/useModalState";
import { useStyles } from '@/hooks/useStyles';

export default {
  name: 'ProjectsComponent',
  components: {
    'ProjectInfo': ProjectInfo,
    'ImageGrid': ImageGrid,
  },
  setup() {
    const projects = ref([]);
    const loading = ref(true);
    const loadedImages = ref({});
    const preventNavigation = ref(false);
    const { setModalState } = useModalState();

    const {
      fullscreenProject,
      clickedImageIndex,
      imagesAreVisible,
      openFullscreen,
      closeFullscreen,
    } = useFullscreen();

    // Methods to compute dynamic classes
    const {
      projectRowClasses,
      imageGridClasses,
      imageContainerClasses,
      thumbnailClasses
    } = useStyles(fullscreenProject, imagesAreVisible);


    const handleHeaderClick = (project) => {
      if (fullscreenProject.value && fullscreenProject.value.id === project.id) {
        closeFullscreen();
      }
    };

    const onImageLoad = (projectId, imageId) => {
      if (!loadedImages.value[projectId]) {
        loadedImages.value[projectId] = {};
      }
      loadedImages.value[projectId][imageId] = true;
    };

    const sortedProjects = computed(() => {
      return [...projects.value].sort((a, b) => {
        const getYear = (date) => {
          if (!date) return 0;
          const years = date.split('-').map((year) => parseInt(year, 10));
          return Math.min(...years);
        };
        return getYear(a.attributes.date) - getYear(b.attributes.date);
      });
    });

    const findAdjacentProject = (currentProject, direction) => {
      const currentIndex = sortedProjects.value.findIndex(p => p.id === currentProject.id);
      const newIndex = (currentIndex + direction + sortedProjects.value.length) % sortedProjects.value.length;
      return sortedProjects.value[newIndex];
    };

    const navigateToProject = async (project, isLastImage) => {
      // First fade out current content
      imagesAreVisible.value = false;

      // Wait for fade out
      await new Promise(resolve => setTimeout(resolve, 300));

      fullscreenProject.value = project;
      const imageIndex = isLastImage ? project.attributes.images.data.length - 1 : 0;
      clickedImageIndex.value = imageIndex;
      loading.value = true;

      try {
        await loadHighResImages(project);
        loading.value = false;

        // Force layout recalculation before scrolling
        await nextTick();
        const imageGrid = document.querySelector('.image-grid-enlarged');
        if (isLastImage) {
          imageGrid.scrollLeft = imageGrid.scrollWidth;
        } else {
          imageGrid.scrollLeft = 0;
        }

        // Fade in new content
        imagesAreVisible.value = true;
      } catch (error) {
        console.error('Error loading high-resolution images:', error);
        loading.value = false;
      }
    }

    const handleImageClick = (project, index, event) => {
      if (!fullscreenProject.value) {
        // When not in fullscreen mode, enter fullscreen
        event.preventDefault();
        event.stopPropagation();
        toggleFullscreen(project, index);
      }
      // In fullscreen mode, do nothing to allow overlay to handle navigation
    };

    const handleModalClick = async (event) => {
      if (!fullscreenProject.value) return;

      const overlay = event.currentTarget;
      const rect = overlay.getBoundingClientRect();
      const clickX = event.clientX - rect.left;
      const containerWidth = rect.width;
      const currentImageGrid = document.querySelector('.image-grid-enlarged');
      const totalImages = fullscreenProject.value.attributes.images.data.length;
      const isAtStart = clickedImageIndex.value === 0;
      const isAtEnd = clickedImageIndex.value === totalImages - 1;

      if (clickX < containerWidth / 2) {
        // Clicking on the left half (navigate previous)
        if (isAtStart) {
          const prevProject = findAdjacentProject(fullscreenProject.value, -1);
          await navigateToProject(prevProject, true);
        } else {
          navigateImage(-1);
        }
      } else {
        // Clicking on the right half (navigate next)
        if (isAtEnd) {
          const nextProject = findAdjacentProject(fullscreenProject.value, 1);
          await navigateToProject(nextProject, false);
        } else {
          navigateImage(1);
        }
      }
    };

    const navigateImage = async (direction) => {
      if (!fullscreenProject.value || clickedImageIndex.value === null) return;

      const project = fullscreenProject.value;
      const totalImages = project.attributes.images.data.length;
      let newIndex = clickedImageIndex.value + direction;

      if (newIndex < 0) {
        const prevProject = findAdjacentProject(project, -1);
        await navigateToProject(prevProject, true);
      } else if (newIndex >= totalImages) {
        const nextProject = findAdjacentProject(project, 1);
        await navigateToProject(nextProject, false);
      } else {
        clickedImageIndex.value = newIndex;
        scrollToImage(project, newIndex);
      }
    };


    const toggleFullscreen = async (project, imageIndex) => {
      if (fullscreenProject.value && fullscreenProject.value.id === project.id) {
        closeFullscreen();
      } else {
        await openFullscreen(project, imageIndex);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeFullscreen();
      } else if (event.key === 'ArrowLeft' && fullscreenProject.value) {
        navigateImage(-1);
      } else if (event.key === 'ArrowRight' && fullscreenProject.value) {
        navigateImage(1);
      }
    };

    const handleGlobalClick = (event) => {
      if (fullscreenProject.value) {
        // If the click is inside the image grid, do nothing
        if (event.target.closest('.image-grid-enlarged')) {
          return; // Prevent closing fullscreen when clicking inside the image grid
        }
        const projectRowElement = event.target.closest('.project-row');
        if (!projectRowElement) {
          closeFullscreen();
        } else {
          const projectId = projectRowElement.getAttribute('data-project-id');
          if (parseInt(projectId) !== fullscreenProject.value.id) {
            closeFullscreen();
          }
        }
      }
    };


    const scrollToImage = async (project, imageIndex) => {
      await nextTick();

      const imageContainers = document.querySelectorAll(
          `.project-row-enlarged[data-project-id="${project.id}"] .image-container-enlarged`
      );

      if (imageContainers[imageIndex]) {
        imageContainers[imageIndex].scrollIntoView({
          behavior: 'auto', // Changed from 'smooth' to 'auto'
          inline: 'center',
        });
        setupObserverForImage(imageContainers[imageIndex]);
      }
    };

    const handleBeforeUnload = (event) => {
      if (preventNavigation.value) {
        // Standard way to trigger the confirmation dialog
        event.preventDefault();
        event.returnValue = '';
      }
    };

    const setupObserverForImage = (imageElement) => {
      const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              imagesAreVisible.value = true;
              observer.disconnect();
            }
          },
          {threshold: 0.5}
      );
      observer.observe(imageElement);
    };

    onMounted(async () => {
      try {
        const data = await fetchProjects();
        projects.value = data;

        projects.value.forEach((project) => {
          loadedImages.value[project.id] = new Array(
              project.attributes.images.data.length
          ).fill(false);
        });
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
      document.addEventListener('keydown', handleKeyDown);
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('close-modal', () => {
        if (fullscreenProject.value) {
          closeFullscreen();
        }
      });
    });



    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleGlobalClick);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('close-modal', closeFullscreen);
    });

    watch(fullscreenProject, (newValue) => {
      setModalState(!!newValue);
    });

    return {
      projects,
      sortedProjects,
      fullscreenProject,
      imagesAreVisible,
      handleImageClick,
      getImageUrl,
      onImageLoad,
      handleModalClick,
      handleHeaderClick,
      // Modal Classes
      thumbnailClasses,
      imageContainerClasses,
      imageGridClasses,
      projectRowClasses
    };
  },
};
</script>


<style scoped lang="scss">

.projects {
  margin-top: 35vh;
}
</style>