import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:1337',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

export const fetchProjects = async () => {
  const cachedData = localStorage.getItem('projects');
  if (cachedData) {
    return JSON.parse(cachedData);
  }

  const response = await apiClient.get('/api/projects?populate=images');
  localStorage.setItem('projects', JSON.stringify(response.data.data));
  return response.data.data;
};


export const getFullUrl = (path) => {
  if (!path) return '';
  const cleanPath = path.startsWith('/') ? path.slice(1) : path;
  return `${apiClient.defaults.baseURL}/${cleanPath}`;
};

export default apiClient;
