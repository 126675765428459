export const useStyles = (fullscreenProject, imagesAreVisible) => {
    const projectRowClasses = (project) => ({
        'project-row-enlarged': fullscreenProject.value?.id === project.id,
        'project-row-normal': !fullscreenProject.value || fullscreenProject.value.id !== project.id,
    });

    const imageGridClasses = (project) => ({
        'image-grid-enlarged': fullscreenProject.value?.id === project.id,
        'image-grid-hidden': fullscreenProject.value && fullscreenProject.value.id !== project.id,
    });

    const imageContainerClasses = (project) => ({
        'image-container-enlarged': fullscreenProject.value?.id === project.id,
        'image-container-hidden': fullscreenProject.value && fullscreenProject.value.id !== project.id,
    });

    const thumbnailClasses = (project) => ({
        'thumbnail-enlarged': fullscreenProject.value?.id === project.id,
        'thumbnail-hidden': fullscreenProject.value && fullscreenProject.value.id !== project.id,
        'images-visible': fullscreenProject.value?.id === project.id && imagesAreVisible.value,
    });

    return {
        projectRowClasses,
        imageGridClasses,
        imageContainerClasses,
        thumbnailClasses
    };
};