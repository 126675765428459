<template>
  <header class="header" @click="handleHeaderClick">
    <!-- Logo and desktop menu -->
    <RouterLink to="/" class="logo-link">
      <p>EVGENIY PAVLOV</p>
    </RouterLink>
    <div class="segment">
      <p>KHARKIV UKRAINE</p>
      <p>1949 – ONGOING</p>
      <p>1972-2004 YEARS ACTIVE</p>
      <p>24 SERIES</p>
      <p>3415 ARTWORKS</p>
    </div>

    <!-- Default menu for desktop -->
    <nav class="default-menu">
      <ul>
        <li><RouterLink to="/exhibitions">EXHIBITIONS</RouterLink></li>
        <li><RouterLink to="/index">INDEX</RouterLink></li>
        <li><RouterLink to="/press">PRESS</RouterLink></li>
        <li><RouterLink to="/contact">CONTACT</RouterLink></li>
      </ul>
    </nav>

    <!-- Burger menu button for mobile -->
    <button class="burger-menu" @click="toggleMenu">MENU</button>

    <!-- Mobile overlay menu -->
    <div v-if="isMenuActive" class="menu-overlay">
      <RouterLink to="/" class="overlay-logo-link" @click="hideMenu">EVGENIY PAVLOV</RouterLink>
      <button class="close-menu" @click="hideMenu">CLOSE</button>
      <ul>
        <li><RouterLink to="/exhibitions" @click="hideMenu">Exhibitions</RouterLink></li>
        <li><RouterLink to="/index" @click="hideMenu">Index</RouterLink></li>
        <li><RouterLink to="/press" @click="hideMenu">Press</RouterLink></li>
        <li><RouterLink to="/contact" @click="hideMenu">Contact</RouterLink></li>
      </ul>
      <div class="bottom-links">
        <a href="https://www.instagram.com" target="_blank">INSTAGRAM</a>
        <a href="https://www.artsy.net" target="_blank">ARTSY</a>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { useModalState } from '@/hooks/useModalState';

const router = useRouter();
const { isModalActive } = useModalState();
const isMenuActive = ref(false);

const toggleMenu = () => {
  isMenuActive.value = !isMenuActive.value;
};

const handleHeaderClick = (event) => {

  if (isModalActive.value) {
    event.preventDefault();
    window.dispatchEvent(new CustomEvent('close-modal'));

    const clickedLink = event.target.closest('a');
    if (clickedLink) {
    }
  }
};

const hideMenu = () => {
  isMenuActive.value = false;
};

const handleResize = () => {
  if (window.innerWidth > 768 && isMenuActive.value) {
    hideMenu();
  }
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>
